.App {
  text-align: left;
}

.home-title {
  text-align: center;
}

.confirmation-question {
  text-align: center;
}

.confirmation-button {
  margin: 3px;
}

.modal-submission {
  margin-bottom: 25px;
}

.icon-button {
  margin-left: 10px;
  margin-right: 10px;
}

.popup-form {
  max-height: 75vh;
}

.form-group label {
  margin-left: 5px;
}

.form-group {
  margin-bottom: 20px;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 30vh;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 12px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

.popup-instructions {
  margin-left: 10px;
  margin-right: 10px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.table-responsive {

  max-height: 65vh;
  max-width: 98vw;
  border: 4px solid;
  margin: 1vw;
}

.sticky-header {
  position: sticky;
  top: -1px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  overflow: auto;
  display: block;
  table-layout: auto;
  }
