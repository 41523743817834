body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: #ffffff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 500px) {
  .modal-area {
    left: 50%;
    top: 50%;
    height: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    max-height: calc(100% - 1em);
  }
}

._modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  line-height: 1;
  background: #f6f6f7;
  border: 0;
  cursor: pointer;
}

._modal-close-icon {
  width: 25px;
  height: 25px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 2;
}

.modal-body {
  padding-top: 0.25em;
}

._hide-visual {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.scroll-lock {
  overflow: hidden;
  margin-right: 17px;
}

.login-form {
  max-width: 375px;
  margin-top: 10vh;
  margin-left: auto !important;
  margin-right: auto !important;
  float: none !important;
}

.invalid-warning {
  color: red;
  text-align: center;
}

.invalid-warning-form {
  color: red;
}

.create-btn {
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  float: right;
}

.searchBar {
  margin-left: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  display:inline-block;
}

.incidentTable {
  margin-left: 1vw;
  margin-right: 1vw;
  max-width: 2000px;
  table-layout: fixed;
  width: 98%;
}

.tableContainer {
  display: table;
  margin: auto;
}

tbody {
  overflow-wrap: anywhere;
}